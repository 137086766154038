@mixin font-definition($FontName, $FontPath, $FontType) {
  src:
    local("#{$FontName} #{$FontType}"),
    url("../fonts/#{$FontPath}/#{$FontPath}-#{$FontType}.ttf")
      format("truetype");
}

// Fonts
@font-face {
  font-family: "#{$openSans} Regular";
  @include font-definition($openSans, $openSansPath, "Regular");
  font-weight: 300;
}

@font-face {
  font-family: "#{$openSans} SemiBold";
  @include font-definition($openSans, $openSansPath, "SemiBold");
  font-weight: 600;
}

@font-face {
  font-family: "#{$openSans} Bold";
  @include font-definition($openSans, $openSansPath, "Bold");
  font-weight: 900;
}

// End Font

// General Styles

body {
  font-family: "Open Sans Regular";
}

.body {
  min-height: 70vh;
}

.spinner-new {
  width: 50px;
  height: 50px;
  color: $lile_0;
  border-width: 7px;
}

// End General Styles

// Font General Styles
h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 1.2!important;
  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3!important;
  text-align: center;
}

h6 {
  font-size: 16px;
  line-height: 1.3!important;
  line-height: 93%;
  text-align: left;
  vertical-align: top;
}

.main-title {
  font-size: 1.7rem;
  font-weight: bold;
}

.main-subtitle {
  font-size: 1.3rem;
  font-weight: bold;
}
// End Fonts General Styles

// Home Styles

.homeImage {
  max-width: 100%;
  width: auto;
}

.carrouserlLarge {
  position: relative;
  display: flex;
  height: 200px;
  width: 100%;
  text-align: center;
  .row {
    height: 100%;
  }
  .cta-bg-image {
    position: absolute;
    width: 800px;
    right: 0;
    top: 0;
  }
  h3 {
    font-size: 2rem;
    color: $light-color;
    line-height: 1.2;
    font-weight: bolder;
  }
  p {
    color: $light-color;
    font-weight: bold;
  }
}

.jumbo-home {
  border-radius: 20px;
  background-image: url("../assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  h3 {
    font-size: 1.2rem;
    text-align: left;
    color: $light-color;
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-bottom: 1.5rem;
  }
  h1 {
    color: $light-color;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 3.5rem;
    span {
      color: #9327fc;
    }
  }
}
$cm-brand-color: #4333a7;
$cs-brand-color: #169998;
.my-pruducts-home {
  .my-products-item {
    display: flex;
    a {
      height: 120px;
      border-radius: 20px;
      background-color: $main-color;
      border: 2px solid lighten($color: $main-color, $amount: 20%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 1rem;
      text-decoration: none;
      color: $light-color;
      &:hover {
        background-color: lighten($color: $main-color, $amount: 20%);
      }
    }
    &.cm-product {
      a {
        background-color: $cm-brand-color;
        border-color: darken($color: $cm-brand-color, $amount: 15%);
        &:hover {
          background-color: lighten($color: $cm-brand-color, $amount: 5%);
          border-color: lighten($color: $cm-brand-color, $amount: 0%);
        }
      }
    }
    &.cs-product {
      a {
        background-color: $cs-brand-color;
        border-color: darken($color: $cs-brand-color, $amount: 15%);
        &:hover {
          background-color: lighten($color: $cs-brand-color, $amount: 5%);
          border-color: lighten($color: $cs-brand-color, $amount: 0%);
        }
      }
    }
    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 0;
    }
    h5 {
      font-size: 1rem;
      font-weight: light;
      margin-bottom: 0;
      letter-spacing: 1px;
    }
  }
}
// End Home Styles

.jumbo-home {
  border-radius: 20px;
  background-image: url("../assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  h3 {
    font-size: 1.2rem;
    text-align: left;
    color: $light-color;
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-bottom: 1.5rem;
  }
  h1 {
    color: $light-color;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 3.5rem;
    span {
      color: #9327fc;
    }
  }
}
$cm-brand-color: #4333a7;
$cs-brand-color: #169998;
.my-pruducts-home {
  .my-products-item {
    display: flex;
    a {
      height: 120px;
      border-radius: 20px;
      background-color: $main-color;
      border: 2px solid lighten($color: $main-color, $amount: 20%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 1rem;
      text-decoration: none;
      color: $light-color;
      &:hover {
        background-color: lighten($color: $main-color, $amount: 20%);
      }
    }
    &.cm-product {
      a {
        background-color: $cm-brand-color;
        border-color: darken($color: $cm-brand-color, $amount: 15%);
        &:hover {
          background-color: lighten($color: $cm-brand-color, $amount: 5%);
          border-color: lighten($color: $cm-brand-color, $amount: 0%);
        }
      }
    }
    &.cs-product {
      a {
        background-color: $cs-brand-color;
        border-color: darken($color: $cs-brand-color, $amount: 15%);
        &:hover {
          background-color: lighten($color: $cs-brand-color, $amount: 5%);
          border-color: lighten($color: $cs-brand-color, $amount: 0%);
        }
      }
    }
    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 0;
    }
    h5 {
      font-size: 1rem;
      font-weight: light;
      margin-bottom: 0;
      letter-spacing: 1px;
    }
  }
}
// End Home Styles

.sidebar-header {
  background-color: $purple_0;
  color: $greybrown_1;
}

.dropdown-toggle {
  padding: 7.25px;
  &::after {
    display: none;
  }
}
.my-products-item {
  a {
    &.soon-inverted {
      background-color: $common_white;
      border-color: $common_white;
      color: $purple_0;
      &.disabled {
        background-color: $card_disabled;
      }
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
.card {
  &.soon-inverted {
    background-color: $common_white;
    border-color: $common_white;
    color: $purple_0;
    &.disabled {
      background-color: $card_disabled;
    }
  }
  &.article {
    .card-body {
      .badge {
        top: -11px;
        border-radius: 8px;
      }
    }
  }
}

.card-article {
  border: none;
  .card-body {
    padding: 0;
  }
}
// Slides Styles

.card-article {
  border: none;
  .card-body {
    padding: 0;
  }
}
// Slides Styles

.slide-bg-gold {
  background-color: $gold_2;
  border-color: $gold_2;
  color: $greybrown_1;
  &.disabled {
    background-color: $gold_0_disabled;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.cm-menu-item {
  background-color: $cm-brand-color;
  border-color: $cm-brand-color;
  color: $light-color;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.cs-menu-item {
  background-color: $cs-brand-color;
  border-color: $cs-brand-color;
  color: $light-color;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.slide-bg-grey {
  background-color: $gray_8;
  border-color: $gray_8;
  color: $greybrown_1;
  &.disabled {
    background-color: $gray_8_disabled;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.slide-bg-blue {
  background-color: $blue_0;
  border-color: $blue_0;
  color: $greybrown_1;
  &.disabled {
    background-color: $blue_0_disabled;
    .btn {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

.slide-bg-green {
  background-color: $green_0;
  border-color: $green_0;
  color: $greybrown_1;
  &.disabled {
    background-color: $green_0_disabled;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.slide-bg-cs {
  background-color: $contact_suite;
  border-color: $contact_suite;
  color: $greybrown_1;
  &.disabled {
    background-color: $green_0_disabled;
  }
  .btn {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.cta-mtking {
  background: linear-gradient(90deg, #310d54 0%, #631aa9 100%);
  margin-bottom: -32px;
}
// End Slides Styles

.productIcon-small {
  display: flex;
  align-items: center;
  .card {
    height: 22px;
    min-width: 31px;
    margin-left: 10px;
    margin-top: 0px;
    .card-body {
      padding: 0px;
      .card-title {
        margin-bottom: 0px;
        font-size: 0.7rem;
        padding: 3px;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1.3;
        font-weight: bold;
      }
    }
  }
  .subscription-name {
    margin-left: 7px;
    text-transform: capitalize;
    width: 100%;
    font-size: 0.7rem;
    line-height: 1.2;
    display: block;
    max-width: 98%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

// End Health & Status box Styles

// Form Styles

input {
  &[type="text"] &[type="email"] &[type="phone"] {
    border-radius: 3px;
    border: 1px solid $purple_0;
  }
}

// End Form Styles

// Breadcrumbs
.breadcrumbs {
  padding: 0 0 24px 0;
  .nav-link {
    padding: 0 8px 0 0;
    text-align: left;
    color: $main-color;
    line-height: 1;
    font-size: 0.85rem;
    &:after {
      content: "|";
      padding-left: 8px;
      margin-top: -2px;
    }
    &:last-child {
      font-weight: bold;
      &::after {
        display: none;
      }
    }
  }
}
// End Breadcrumbs styles
.bg-lile {
  background: $lile_0;
  background: linear-gradient(218deg, $lile_0 0%, $lile_2 100%);
  a {
    text-decoration: none;
    h2 {
      text-align: left;
      color: $common_white;
      font-size: 2.5rem;
      line-height: 1.1;
    }
  }
}

.accordion-button,
.form-control {
  &:focus {
    border-color: $purple_0;
    box-shadow: 0 0px 4px $lile_0;
  }
  &:disabled {
    background-color: $table_stripped_grey;
  }
  &.gold-focus {
    border-color: $gold_0;
    box-shadow:
      inset 0 1px 1px $purple_9,
      0 0 8px $gold_0_a;
  }
  &.status-warning-focus {
    border-color: $status_warning;
    box-shadow:
      inset 0 1px 1px $purple_9,
      0 0 8px $status_warning;
  }
  &.status-success-focus {
    border-color: $status_success;
    box-shadow:
      inset 0 1px 1px $purple_9,
      0 0 8px $status_success;
  }
}

.form-switch {
  &.product-switch {
    .form-check-input {
      &:checked {
        background-color: $status_success;
        border-color: $status_success;
      }
    }
  }
}

select {
  border-radius: 3px !important;
}

.carousel {
  .container-card {
    width: 24%;
    .img-floating {
      transform: translate(77px, 62px);
      max-width: 160px;
      max-height: 148px;
      z-index: 1;
      position: relative;
    }
    .card {
      border-radius: 3px;
      border-width: 1px;
      &.gold {
        border-color: $gold_0;
      }

      &.purple {
        border-color: $purple_0;
      }

      &.petro {
        border-color: $petro_0;
      }

      &.grey {
        border-color: $gray_8;
      }
      .card-body {
        padding: 0px;

        .slide-title {
          margin-top: 42px;
          min-height: 50px;
          h2 {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        .slide-bg {
          margin: -1px;
          .card-body {
            padding: 5px;
          }
        }
      }
    }
  }
  &.slide {
    .carousel-control-prev {
      left: -37px;
      top: 150px;
      width: 5%;
      .carousel-control-prev-icon {
        filter: invert(1);
      }
    }
    .carousel-control-next {
      right: -37px;
      top: 150px;
      width: 5%;
      .carousel-control-next-icon {
        filter: invert(1);
      }
    }
  }
}

.card-messages {
  .card-body {
    background-color: $greybrown_1;
  }
  .accordion-item {
    .accordion-header {
      button {
        padding: 1px;
        background-color: $purple_0;
        color: $greybrown_1;
        border-bottom-color: $purple_0;

        &::after {
          color: $greybrown_1;
        }
      }
    }
    .accordion-body {
      padding: 0px;
    }
  }
}

.slide-title {
  h2 {
    text-align: left;
    color: $gray_8;
  }
}

// Tables Styles

.table-striped {
  > thead {
    > tr {
      --bs-table-accent-bg: white;
      color: var(--bs-table-striped-color);
      border: 1px solid #e2e3e5;
      vertical-align: middle;
      th {
        font-size: 16px;
        padding-left: 12px;
        padding-right: 12px;

        &.refreshButton {
          text-align: right;
        }
      }
    }
  }

  > tbody {
    > tr:nth-of-type(odd) {
      > * {
        --bs-table-stripped-bg: #{$table_stripped_grey_2};
        --bs-table-accent-bg: transparent;
        --bs-table-bg-type: transparent;
        --bs-table-active-bg: #{$table_stripped_grey_1};
        color: var(--bs-table-striped-color);
      }
    }

    > tr:nth-of-type(even) {
      > * {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
        color: var(--bs-table-striped-color);
      }
    }

    tr {
      td {
        padding-left: 12px;
        font-size: 15px;
      }
    }
  }
  &.ticketTable {
    tbody {
      tr {
        &:hover {
          background-color: $greybrown_0;
        }
        td:nth-child(1),
        td:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
}

.change-history-table tbody tr td .accordion-item .accordion-header button {
  background-color: #f2f2f2f2 !important;
}

.table {
  & > :not(caption) {
    & > * > * {
      box-shadow: none;
    }
  }
}

.change-history-table tbody tr td .accordion-item .accordion-header button {
  background-color: #f2f2f2f2 !important;
}

.table {
  & > :not(caption) {
    & > * > * {
      box-shadow: none;
    }
  }
}

// End Tables Styles

.alert-danger {
  color: $red_0;
}

.alert {
  border-radius: 3px;
  margin: 0px;
  padding: 5px;
  .alert-heading {
    margin: 0px;
  }

  span {
    margin: 0px 8px;
    vertical-align: middle;
  }

  button {
    &.saas {
      height: 25px;
      line-height: 14px;
      padding: 5px 20px;
    }
  }
}

.line-height-fix {
	line-height: 1.3;
}

.small-label {
	&.medium {
		font-size: 1rem;
	}
}

.text-links {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: $purple_0;
  text-decoration: none;

  &:hover {
    color: $purple_0_disabled;
  }

  &.change-password {
    text-decoration: underline;
    font-size: 14px;
  }
}

.line-divider {
  border-bottom: $purple_0 solid 1px;
}

.edit {
  .parentSVG {
    border: none;
    background-color: transparent;
    svg {
      path {
        fill: $red_0;
      }
      &:hover {
        background-color: $red_0;
        padding: 2px;
        path {
          fill: $common_white;
        }
      }
      &.editSVG {
        path {
          fill: $common_white;
        }
        &:hover {
          background-color: unset;
          padding: unset;
          path {
            fill: $common_white;
          }
          circle {
            fill: $red_0;
          }
        }
      }
    }
    &.warning {
      svg {
        path {
          fill: $gold_0;
        }
        &:hover {
          background-color: $gold_0;
          path {
            fill: $common_white;
          }
        }
      }
    }
  }
}

.icon-img {
  background-color: $common_white;
  &.config-clicked {
    background-color: $purple_0;
  }
}

.carousel {
  &.slide {
    .carousel-control-prev {
      left: -37px;
      top: 150px;
      width: 5%;
      .carousel-control-prev-icon {
        filter: invert(1);
      }
    }
    .carousel-control-next {
      right: -37px;
      top: 150px;
      width: 5%;
      .carousel-control-next-icon {
        filter: invert(1);
      }
    }
  }
}

.badge {
  &.cm-status {
    margin-right: 10px;
    color: $common_white;
    padding: 6px 8px;
    margin: 6px 0;
    letter-spacing: 0.3px;
    line-height: 1;
    h5 {
      text-transform: capitalize;
    }
    h6 {
      line-height: 1.3;
      font-size: 16px;
    }
  }
}

.container-notifications {
  width: 300px;

  .accordion-item {
    background-color: $purple_9;
    .accordion-header {
      button {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: $purple_9;
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid $purple_0;
        --bs-accordion-border-color: none;
        --bs-accordion-inner-border-radius: 0px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: $purple_0;
  box-shadow:
    inset 2px 2px 2px $scrollbar_0,
    inset -2px -2px 2px $scrollbar_1;
}

::-webkit-scrollbar-track {
  background-color: $common_white;
  border-radius: 4px;
  background: $common_scroll_bg;
  box-shadow: inset 0 0 20px $greybrown_0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.mt-6 {
  margin-top: ($spacer * 3.25) !important;
}

.mt-7 {
  margin-top: ($spacer * 3.5) !important;
}

.mt-8 {
  margin-top: ($spacer * 3.75) !important;
}

.mt-9 {
  margin-top: ($spacer * 4) !important;
}

.vr {
  height: 100%;
  &.article {
    color: $greybrown_0;
    width: 3px;
  }
  &.nav-logo {
    color: $purple_0;
    width: 1px;
    margin-left: 14px;
    height: 50px;
  }
}

.position-right {
  float: right;
}

.icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  & > svg {
    width: 100%;
    height: auto;
  }
  &.icon-md {
    width: 22px;
    height: 22px;
  }
}
.icon-status-subcription {
  .icon {
    svg {
      margin-top: -8px;
    }
  }
}

.arrow {
	background-image: url("../assets/images/arrow.png");
	background-repeat: no-repeat;
	background-size: 80%;
    background-origin: padding-box;
    background-position-x: 14px;
    background-position-y: -5px;
}

.icon-copy {
  svg {
    margin-top: 0px;
    line-height: 1;
  }
}

.sass-accordion-description {
  display: flex;
  color: $color_fonts;
  font-size: 12px;
  line-height: 1.3;
  flex-direction: column;
  &.inline-block {
    display: inline-block;
  }
}

.cursor-default {
  cursor: default;
}
.font-weight-normal {
  font-weight: normal;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-lighter {
  font-weight: lighter;
}
.back-link {
  margin-top: -15px;
  margin-bottom: 15px;
}

.purple-header.accordion-header {
  button {
    background-color: $purple_1;
    color: $common_white;
    box-shadow: none;
    &::after {
      background-image: url($chevron);
      transform: rotate(-90deg);
    }
    &:not(.collapsed) {
      color: $common_white;
      background-color: $purple_0;
      border-radius: 0 !important;
      &::after {
        transform: rotate(0deg);
      }
    }
  }
}

.modal-scrollable {
  max-height: 65vh;
  overflow-y: scroll;
}

.table-accordion-header {
  [aria-expanded="true"] {
    & td {
      .accordion-arrow svg {
        transform: rotate(90deg);
        transition: 0.2s ease-in-out;
      }
    }
  }
  [aria-expanded="false"] {
    & td {
      .accordion-arrow svg {
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
      }
    }
  }

  &::after {
    background-image: url($chevron);
  }
}

.table-accordion-header {
  &::after {
    background-image: url($chevron);
  }
}

.tenant-status-top-5 {
  .accordion-item {
    border-radius: 0 !important;
  }
}

.tenant-header {
  &:after {
    content: none;
  }
  &.purple-header {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    overflow: hidden;
    border: 2px solid $main-color;
    background-color: $main-color;
    color: $light-color;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 8px;
    margin: 0;
  }
}
.accordion {
  background-color: $light-color;
}
sup.required {
  color: $red_0;
}

.select__control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  .select__value-container {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.tooltip-inner {
  min-width: 20px;
  padding: 8px 8px;
  text-align: left;
}
.trashIconToolip {
  .tooltip-inner {
    min-width: unset;
    padding: 1px 5px;
  }
}

fieldset {
  margin: 0 0 32px 0;
  padding: 0;
  border: none;
  legend {
    font-size: 18px;
    margin-top: -26px;
    background-color: $light-color;
    padding: 0;
    width: fit-content;
    margin-left: 0;
  }
}

.PhoneInput {
  &Input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $common_border;
    background-color: $common_white;
    background-clip: padding-box;
    border: 1px solid $border_btn_grey;
    appearance: none;
    border-radius: 0.375rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  &CountryIcon {
    svg {
      vertical-align: top;
    }
  }
  &.disabled {
    select {
      pointer-events: none;
      background-color: $table_stripped_grey;
    }
    input {
      pointer-events: none;
      background-color: $table_stripped_grey;
    }
  }
}

.parentSVG {
  &.secondary {
    border: none;
    background-color: transparent;
    svg {
      path {
        fill: $purple_0;
      }
      &:hover {
        background-color: $purple_0;
        padding: 2px;
        path {
          fill: $light-color;
        }
      }
    }
  }
  &.warning {
    svg {
      &.status-warning-focus {
        path {
          fill: $gold_0;
        }
        &:hover {
          background-color: $gold_0;
          path {
            fill: $common_white;
          }
        }
      }
    }
  }
}

.layout-page {
  min-height: 100vh;
  position: relative;
  .flex-grow-1 {
    position: relative;
    min-height: 94vh;
    overflow: hidden;
    padding-bottom: 30px;
  }
  .bg-login {
    background-image: url("../assets/images/bg-login.jpg");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    min-height: 97vh;
    height: 100%;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
  }
}
.help-component {
  z-index: 1;
  display: flex;
  position: absolute;
  align-content: flex-end;
  top: -30px;
  width: 100%;
  h6 {
    color: $light-color;
    font-weight: bold;
    margin-right: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.3;
  }
  a {
    color: $light-color;
    text-decoration: underline;
    font-size: 0.75rem;
    line-height: 1.3;
  }
}
.validation-error {
  margin-top: 5px;
  color: $red_0;
  font-size: 0.875em;
}
.no-bullets {
  list-style-type: none;
}

.contact-suite {
  .sass-accordion-header {
    .accordion-item {
      background-color: $middle-color;
    }
    .accordion-button {
      background-color: $middle-color;
      color: $common_white;
      padding: 8px 10px;
      border-radius: 5px !important;
      &:not(.collapsed) {
        background-color: $main-color;
        color: $common_white;
        border-radius: 5px !important;
      }
    }
  }
  .accordion-body {
    .form-label {
      margin-bottom: 0.25rem;
    }
  }
}

.sass-accordion-header {
  .accordion-button {
    box-shadow: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid $middle-color;
    padding-left: 0;
    font-size: 17px;
    &::after {
      background-image: url($chevron);
      transform: rotate(-90deg);
    }
    &:not(.collapsed) {
      border-radius: 5px !important;
      background-color: $common_white;
      color: $common_white;
      &::after {
        transform: rotate(0deg);
      }
    }
  }
}

.change-history-table {
  .accordion-button {
    &:not(.collapsed) {
      color: $purple_0;
    }
  }
}
.container-registration {
  background-color: $common_white;
  border-radius: 8px;
  opacity: 0.95;
  max-height: 100%;
  margin-bottom: 50px;
}
.col-login {
  background-color: $light-color;
  border-radius: 8px;
  opacity: 0.95;
}
.valid {
  font-size: 0.85rem;
  align-items: center;
  font-size: 0.85rem;
  align-content: center;
  line-height: 1.3;
  svg {
    width: 14px;
    height: 14px;
  }
}
