.sk-spinner-pulse {
  -webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
  animation: sk-pulseScaleOut 1s infinite ease-in-out;
  background-color: $gray_8;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin: 40px auto;

  &.small {
    width: 20px;
    height: 20px;
    margin: 10px;
  }
  &.light {
    background-color: $common_white;
  }
}

.caption {
  margin-top: -42px;
  font-style: italic;
}

@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.spinner-7 {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: $lile_0;
  box-shadow:
    calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: s7 1s infinite steps(8);
  margin: 40px auto;

  &.small {
    width: 20px;
    height: 20px;
    margin: 10px;
  }
  &.light {
    background-color: $common_white;
  }
}

@keyframes s7 {
  100% {
    transform: rotate(1turn);
  }
}
