.login-forgotpass {
  color: $color_fonts;
  font-size: 12px;
}
.login-fix-height {
  min-height: 219px;
  height: 80vh;
}
.success-reset-page {
  background-color: $common_white;
  border-radius: 8px;
  opacity: 0.95;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
  width: 650px;
}
