.btn {
  &.saas {
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: inherit;
    text-align: center;
    &.btn-sm {
      font-size: 15px;
    }
    &.btn-lg {
      min-width: 197px;
    }
    &.btn-gold {
      background-color: $gold_2;
      color: $greybrown_1;
      &:hover {
        background-color: $common_white;
        color: $gold_2;
        .circle-plus {
          &:before {
            border: 1px $gold_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $gold_2;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $gold_2;
              }
            }
          }
        }
      }
    }

    &.btn-purple,
    &.btn-primary {
      background-color: $green_btn_primary_0;
      color: $common_white;
      &:hover {
        background-color: $green_btn_primary_3;
        color: $common_white;
        .circle-plus {
          &:before {
            border: 1px $green_btn_primary_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $green_btn_primary_0;
            }
            &:hover {
              background-color: $green_btn_primary_3;
              padding: 2px;
              path {
                fill: $common_white;
              }
            }
          }
        }
      }
      .spinner-grow {
        margin-left: 5px;
      }
    }

    &.btn-light-purple {
      background-color: $purple_1;
      color: $common_white;
      &:hover {
        background-color: $common_white;
        color: $purple_1;
        .circle-plus {
          &:before {
            border: 1px $purple_1 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $purple_1;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $purple_1;
              }
            }
          }
        }
      }
    }

    &.btn-primary {
      border-color: $green_btn_primary_0;
      &.attachment {
        border-radius: 25px;
      }
    }

    &.btn-petro {
      background-color: $petro_0;
      color: $greybrown_1;
      &:hover {
        background-color: $common_white;
        color: $petro_0;
        .circle-plus {
          &:before {
            border: 1px $petro_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $petro_0;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $petro_0;
              }
            }
          }
        }
      }
    }

    &.btn-grey {
      background-color: $gray_8;
      color: $greybrown_1;
      &:hover {
        background-color: $common_white;
        color: $gray_8;
        .circle-plus {
          &:before {
            border: 1px $gray_8 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $gray_8;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $gray_8;
              }
            }
          }
        }
      }
    }

    &.btn-secondary {
      background-color: $purple_0;
      border-color: $purple_0;
      color: $common_white;
      &:hover {
        background-color: $purple_btn_hover;
        color: $common_white;
        .circle-plus {
          &:before {
            border: 1px $common_white solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $common_white;
            }
            &:hover {
              background-color: $purple_btn_hover;
              padding: 2px;
              path {
                fill: $common_white;
              }
            }
          }
        }
      }
    }

    &.btn-alternative {
      background-color: $common_white;
      color: $purple_0;
      border: 1px $purple_0 solid;
      &:hover {
        background-color: $grey_btn_hover;
        color: $purple_0;
        border: 1px $purple_0 solid;
        .circle-plus {
          &:before {
            border: 1px $purple_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $common_white;
            }
            &:hover {
              background-color: $grey_btn_hover;
              padding: 2px;
              path {
                fill: $purple_0;
              }
            }
          }
        }
      }
    }

    &.btn-green {
      background-color: $green_0;
      color: $common_white;
      &:hover {
        background-color: $common_white;
        color: $green_0;
        border: 1px $green_0 solid;
        .circle-plus {
          &:before {
            border: 1px $green_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $green_0;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $green_0;
              }
            }
          }
        }
      }
    }

    &.btn-red {
      background-color: transparent;
      color: $red_0;
      border: 1px $red_0 solid;
      &:hover {
        background-color: $red_0;
        color: $common_white;
        border: 1px $red_0 solid;
        .circle-plus {
          &:before {
            border: 1px $red_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $red_0;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $red_0;
              }
            }
          }
        }
      }
    }

    &.btn-blue {
      background-color: $blue_0;
      color: $greybrown_1;
      &:hover {
        background-color: $common_white;
        color: $blue_0;
        .circle-plus {
          &:before {
            border: 1px $blue_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $blue_0;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $blue_0;
              }
            }
          }
        }
      }
    }

    &.btn-green-edit {
      background-color: $green_btn_primary_0;
      color: $greybrown_1;
      &:hover {
        background-color: $green_btn_edit_3;
        color: $common_white;
        border: 1px $green_btn_edit_3 solid;
        .circle-plus {
          &:before {
            border: 1px $green_btn_primary_0 solid;
          }
        }
        .parentSVG {
          svg {
            path {
              fill: $green_btn_primary_0;
            }
            &:hover {
              background-color: $common_white;
              padding: 2px;
              path {
                fill: $green_btn_primary_0;
              }
            }
          }
        }
      }
    }

    &.btn-attachment {
      background-color: $gray_0;
      color: $common_black;
      border: 1px $gray_1 solid;
      &:hover {
        filter: brightness(0.8);
      }
      .parentSVG {
        svg {
          path {
            fill: $red_0;
          }
          &:hover {
            background-color: $common_white;
            padding: 2px;
            path {
              fill: $red_0;
            }
          }
        }
      }
    }

    &.mr-1 {
      margin-right: ($spacer * 0.25) !important;
    }

    .circle-container {
      position: relative;
      margin-top: -27px;
      margin-left: 157px;
    }
    .circle-plus {
      &:before {
        content: "\FF0B";
        display: inline-flex;
        justify-content: center;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        border: 1px solid $common_white;
        font-size: 22px;
      }
    }

    p {
      text-align: left;
      margin: 0px;
      height: 100%;
    }

    .parentSVG {
      border: none;
      padding: 0px;
      margin: 0px;
      background-color: transparent;
      display: flex;
      svg {
        padding: 0px !important;
        path {
          fill: $common_white;
        }
      }
    }
    &.hover-dark {
      &:hover {
        background-color: $purple_2;
        color: $greybrown_0;
      }
    }
    &.show-focus {
      &:focus,
      &:focus-visible {
        box-shadow: 0 0 0.2rem 0.2rem $purple_1;
      }
      &.btn-secondary {
        &:focus,
        &:focus-visible {
          box-shadow: 0 0 0.2rem 0.2rem $purple_2;
        }
      }
    }
  }
}

.slide-bg {
  .btn {
    border: 1px $common_white solid;

    &:hover {
      border: 1px $greybrown_1 solid;
    }
  }
}

.imgButton {
  padding: 0px;
  border: 0px;
}

svg.trashTableSVG,
svg.editTableSVG,
svg.addTableSVG {
  width: 14px;
  height: 16px;
}

.trashIcon {
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 50% !important;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 18.3px;
    path {
      fill: $red_0;
    }
  }
  &.parentSVG:hover {
    background-color: $red_0;
    box-shadow: 0px 0px 4px 4px $red_0;

    path {
      fill: $common_white;
    }
  }
}

svg.noSize {
  width: unset;
  height: unset;
}

.ticketTable {
  .colName,
  .order {
    display: inline;
  }
}

.sortIcon {
  border: none;
  transition: transform 500ms ease;
  &.active {
    svg {
      transform: scale(1.2);
      path {
        fill: $common_black;
      }
    }
  }
}
.btn-fit-content {
  width: fit-content;
}

.saas-transparent {
  background-color: transparent;
}
.input-group {
  button {
    &.parentSVG {
      background-color: inherit;
      border: none;
    }
  }
}

button.icon-only {
  &.parentSVG {
    background-color: inherit;
    border: none;
  }
}
