.navbar-brand {
  margin-right: 5px;
  position: relative;
  left: 0;
}

.nav-item {
  color: $color_fonts;
}

.navbar-expand {
  .navbar-nav {
    padding-top: 10px;
    flex-wrap: wrap;
    .nav-link {
      padding-left: 7px;
      padding-right: 0px;
      padding: 2px 0px 2px 7px;
      color: $purple_0;
      display: inline-flex;
      line-height: 1.2;
      white-space: nowrap;
      &:before {
        content: "|   ";
        display: inline;
        padding-right: 7px;
      }
      &:first-child {
        padding-left: 3px;
        &:before {
          content: "";
          display: inline;
        }
      }
    }
  }
}

.navbar-expand .navbar-nav .nav-link:before {
  content: "|";
  display: inline;
}

.header {
  &.header-sticky {
    background-color: $main-color;
  }
}

.nav-header {
  .navbar-collapse {
    .navbar-nav {
      flex-shrink: 0;
    }
  }
}

.navbar-nav > .nav-link:last-child {
  font-weight: 700;
  &::before {
    font-weight: 400;
  }
}
.tenat-header-name {
  color: $light-color;
  font-size: 1.2rem;
  font-weight: bold;
}
