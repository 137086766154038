.dropdown-products {
  .dropdown-menu {
    min-width: 220px;
  }
}

.dropdown-menu {
  &.dropdown-menu-end {
    background-color: $purple_9;
    border-top: 5px solid $lile_0;
    margin-right: -5px;
    &::before {
      content: "";
      position: absolute;
      right: 9px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $lile_0;
    }

    .tenants {
      .dropdown-item {
        &.active {
          font-weight: 700;
          background-color: $common_white;
          color: $common_border;
        }
      }
    }

    .dropdown-item {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;

      &:hover {
        font-weight: 700;
        background-color: transparent;
      }
      &:active {
        background-color: unset;
        color: var(--bs-dropdown-link-color);
      }

      p {
        margin-bottom: 0px;
      }

      .card {
        width: 91px;
        min-height: 110px;
        transform: scale(1);
        border: 0;
        border-radius: 10px;
        transition: transform 300ms ease;
        &:hover {
          transform: scale(0.9);
        }

        .card-body {
          padding: 10px;
          text-align: center;
          .card-title {
            font-size: 40px;
            font-weight: 900;
          }
          .card-subtitle {
            font-size: 10.9px;
            white-space: break-spaces;
          }
        }
      }
    }
  }
  .active-route {
    padding-left: 16px;
  }
  .route {
    padding-left: 16px;
  }
  .route-divider {
    border-bottom: 1px solid var(--bs-dropdown-divider-bg);
    &.tenants {
      &:last-child {
        border-bottom: 0px;
      }
    }
    .scrollable {
      max-height: 185px;
      overflow-y: auto;
    }
  }
  .user-color {
    p {
      color: $purple_0;
      font-weight: 600;
    }
  }
}

.user-tenants {
  .dropdown-menu {
    &.dropdown-menu-end {
      background-color: $common_white;
      border-top: 5px solid $lile_0;
      margin-right: -5px;
      padding-top: 0px;
      .container-fluid {
        .row {
          background-color: $purple_9;
        }
      }
    }
  }
}
