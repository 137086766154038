footer {
  margin-top: 2rem;
  position: relative;
  &.bg-purple {
    background-color: $purple_0;
  }

  section {
    .container {
      h6 {
        color: $greybrown_1;
        margin-bottom: 12px;
      }

      ul {
        list-style: none;
        padding-left: 0;
        column-count: 2;
        li {
          margin-bottom: 5px;
          display: inline-block;
          .text-links {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.3;
            color: $greybrown_0;
            text-decoration: none;

            &:hover {
              color: $greybrown_0_disabled;
            }
          }
        }
      }

      .col-divider {
        border-left: 2px solid $purple_2;
        padding: 0px;
      }
      .navbar-brand {
        margin-right: unset;
        position: unset;
        left: unset;
      }
    }
  }
  .copyright {
    color: $greybrown_0;
    font-size: 0.8rem;
    .text-links {
      color: $greybrown_0;
      text-decoration: underline;
      font-size: 0.8rem;
      &:hover {
        color: $greybrown_0_disabled;
      }
    }
  }
}
