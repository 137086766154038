// General Colors
$main-color: #000;
$light-color: #fff;
$middle-color: #969696;

// Brand Products
$cm-brand-color: #4333a7;
$cs-brand-color: #169998;

// ------
$color_fonts: #3a424d;
$color_bg: #f0eee8;

$purple_0: #000000;
$purple_0_disabled: #0000007d;
$purple_0_a: rgba(72, 61, 84, 0.6);
$purple_3: #000000;
$purple_1: #696073;
$purple_2: #2a1d38;
$purple_9: #f2f0f4;

$lile_0: #9527fc;
$lile_2: #320d55;

$greybrown_0: #d8d4ce;
$greybrown_0_disabled: #d8d4ce7d;
$greybrown_1: #eeecea;
$greybrown_2: #c1bab0;

$gold_0: #fdc825;
$gold_0_disabled: #ecdfb6;
$gold_0_a: rgba(196, 167, 120, 0.6);
$gold_1: #ffd964;
$gold_2: #f0b500;

$gold_badge: #e78a00;

$petro_0: #15aead;
$petro_0_disabled: #a7d6d5;
$petro_1: #21d1d0;
$petro_2: #009291;

$green_0: #5a9e50;
$green_0_disabled: #5a9e507d;
$green_1: #accea7;
$green_2: #246816;

$blue_0: #387efc;
$blue_0_disabled: #387efc7d;
$blue_1: #accea7;
$blue_2: #246816;

$gray_0: #e9e9e9;
$gray_1: #8d8d8d;
$gray_8: #333333;
$gray_8_disabled: #3333337d;

$red_0: #d32642;
$red_1: #ff3f58;
$red_2: #e57f8f;

$green_btn_edit_0: #00b046;
$green_btn_edit_0_disabled: #94bda4;
$green_btn_edit_1: #00d354;
$green_btn_edit_2: #028b38;
$green_btn_edit_3: #0b7133;

$green_btn_primary_0: #00b046;
$green_btn_primary_0_disabled: #94bda4;
$green_btn_primary_1: #00d354;
$green_btn_primary_2: #028b38;
$green_btn_primary_3: #0b7133;

$royal_purple_0: #5541ce;
$royal_purple_0_disabled: #736aa9;
$royal_purple_1: #492cf6;
$royal_purple_2: #2b1a91;

$blue-badge: #0049cc;
$purple-badge: #7d15ae;

$common_white: #ffffff;
$common_black: #000000;
$common_border: #212529;
$common_scroll_bg: #f8f9fa;
$common_shadow_black: #040404da;

$border_btn_grey: #ced4da;
$border_btn_purpleblue: $purple_1;
$accordion_border: #dee2e6;
$bootstrap_lightgrey: #d3d3d3;
$table_stripped_grey_header: #e2e3e5;
$table_stripped_grey: #f2f2f2;
$table_stripped_grey_1: rgba(0, 0, 0, 0.1);
$table_stripped_grey_2: rgba(0, 0, 0, 0.5);
$status_btn_grey: #fafafa;
$status_success: #5a9e50;
$status_warning: #ff9110;
$pagination_disabled: #6c757d;
$pagination_filled: #6a6a6a;
$pagination_focus: rgba(13, 110, 253, 0.25);
$scrollbar_0: rgba(255, 255, 255, 0.25);
$scrollbar_1: rgba(0, 0, 0, 0.25);
$spinner_bg: rgba(0, 0, 0, 0.3);
$case_activity: rgba(33, 37, 41, 0.4);
$card_disabled: rgba(255, 255, 255, 0.4901960784);
$grey_btn_hover: #f2efef;
$purple_btn_hover: #363636;
$contact_suite: #24b993;
